import React from 'react';

import { IconButton, Zoom } from '@mui/material';

import useStyles from './styles';

import ClearIcon from '../../assets/cross.svg';

interface Props {
    className?: string;
    onClick?: () => void;
    visible?: boolean;
    'data-testid'?: string;
}

const transitionTimeout = { enter: 150, exit: 90 };

const ClearButton: React.FC<Props> = ({ onClick, className, visible }) => {
    const { classes, cx } = useStyles();
    return (
        <Zoom in={visible} timeout={transitionTimeout}>
            <IconButton className={cx(classes.button, className)} onClick={onClick} size="large"  data-testid="clearButton">
                <ClearIcon className={cx(classes.icon, { [classes.hidden]: !visible })}/>
            </IconButton>
        </Zoom>
    );
};

export default React.memo(ClearButton);
